import escapeRegExp from 'src/utils/escapeRegExp'

const script = document.currentScript
const origin = new URL(script.src, location).origin

// Ignore browsers older than Safari 13.1 and leaky bots
if (typeof ResizeObserver != 'undefined' && origin && location.origin) {

Honeybadger.configure({
  apiKey: 'hbp_wWUn8K1HHQsenTR6lKhO6L9A2f7CXj2C0lM4',
  environment: script.dataset.env,
  maxErrors: 10,
})

Honeybadger.beforeNotify(notice => {
  if (!notice.stack.includes(origin) && !notice.stack.includes(location.origin))
    return false

  if (
    (([ origin, pathname, search ]) =>
      new RegExp(`${origin}(:443)?${pathname}(${search})?:1:`)
    )([ 'origin', 'pathname', 'search' ].map(key =>
      escapeRegExp(location[key])
    )).test(notice.stack)
  ) return false

  if (notice.stack.includes('//js.hsforms.net/forms/v2.js')) return false
  if (notice.stack.includes('/assets/fontawesome-')) return false
  if (/^[\$A-Za-z]{2}\[[A-Za-z]\]\.push is not a function$/.test(notice.message))
    return false
  if (/^\$\(\.\.\.\)\.[a-z]+ is not a function$/.test(notice.message))
    return false
  if (/\.forEach is not a function$/.test(notice.message)) return false

  if (/\((script in )?https?:\/\//.test(notice.message)) return false

  if ([
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded',
    'requestAnimationFrame is not defined',
    'reinitializeResizeIframe is not defined',
    'Application is not defined',
    'hbspt is not defined',
    'React is not defined',
    'ReactDOM is not defined',
    '$ is not defined',
    'bootstrap is not defined',
    "Can't find variable: hbspt",
    "Can't find variable: React",
    "Can't find variable: bootstrap",
    "Can't find variable: Application",
    "Can't find variable: reinitializeResizeIframe",
    "ReferenceError: Can't find variable: hbspt",
    "ReferenceError: Can't find variable: bootstrap",
    'UnhandledPromiseRejectionWarning: TypeError: Failed to fetch',
    'UnhandledPromiseRejectionWarning: Error: Failed to load Stripe.js',
    'UnhandledPromiseRejectionWarning: Error: Stripe.js not available',
    "Bootstrap's JavaScript requires jQuery. jQuery must be included before Bootstrap's JavaScript.",
    "Failed to execute 'webkitMatchesSelector' on 'Element': '*[object Object]' is not a valid selector.",
    `Blocked a frame with origin "${location.origin}" from accessing a cross-origin frame.`,
    `UnhandledPromiseRejectionWarning: SecurityError: Blocked a frame with origin "${location.origin}" from accessing a cross-origin frame.`,
  ].includes(notice.message)) return false
})

}
